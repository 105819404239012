import type { HotelCoordinate } from '@dx-ui/gql-types';
import { env } from '@dx-ui/framework-env';
import GoogleMapReact from 'google-map-react';
import { useTranslation } from 'next-i18next';
import { useRef } from 'react';
import { InfoWindow } from '@dx-ui/osc-map';
import { useRouter } from 'next/router';
import { getHotelAddressLink } from '@dx-ui/osc-hotel-address';
import type { GetHotelLayoutQuery } from '@dx-ui/queries-dx-property-ui';
import Icon from '@dx-ui/osc-icon';

type TMapCoords = {
  lat: number | undefined;
  lng: number | undefined;
};

type TMapProps = {
  coordinate?: HotelCoordinate | null;
  hotelName: string | null;
  brandCode?: string;
  ctyhocn: string;
  address: NonNullable<NonNullable<GetHotelLayoutQuery['hotelPageTemplate']>['hotel']>['address'];
  hideBrandLogo?: boolean;
};

const Map = ({ address, brandCode, coordinate, ctyhocn, hotelName, hideBrandLogo }: TMapProps) => {
  const language = useRouter().locale || 'en';

  const mapOptions = useRef({
    gestureHandling: 'cooperative',
    zoomControl: true,
  });

  if (!coordinate?.latitude || !coordinate?.longitude) {
    return null;
  }

  const defaultCenter = { lat: coordinate?.latitude, lng: coordinate?.longitude };

  const apiIsLoaded: GoogleMapReact.Props['onGoogleApiLoaded'] = ({ map, maps }) => {
    const number = 2;

    const scale = number ** map.getZoom();

    const coordinateLatLng = new maps.LatLng(coordinate.latitude, coordinate.longitude);
    const worldCoordinateCenter = map?.getProjection()?.fromLatLngToPoint(coordinateLatLng);
    const code = -60;
    const pixelOffset = new maps.Point(0, code / scale);

    const worldCoordinateNewCenter = new maps.Point(
      worldCoordinateCenter.x - pixelOffset.x,
      worldCoordinateCenter.y + pixelOffset.y
    );

    const newCenter = map.getProjection().fromPointToLatLng(worldCoordinateNewCenter);

    map.setCenter(newCenter);
    const elem = document.querySelector('[aria-label="Map"]');
    elem && elem.removeAttribute('aria-roledescription');
  };

  const GoogleMapReactWithChildren = GoogleMapReact as unknown as React.FC<
    React.PropsWithChildren<GoogleMapReact.Props>
  >;

  return (
    <GoogleMapReactWithChildren
      bootstrapURLKeys={{ key: env('MAP_API_KEY'), language }}
      options={mapOptions.current}
      defaultCenter={defaultCenter}
      defaultZoom={16}
      yesIWantToUseGoogleMapApiInternals={true}
      hoverDistance={20}
      onGoogleApiLoaded={apiIsLoaded}
    >
      <ToolTip
        lat={defaultCenter.lat}
        lng={defaultCenter.lng}
        address={address}
        brandCode={hideBrandLogo ? undefined : brandCode}
        ctyhocn={ctyhocn}
        hotelName={hotelName}
      />
      <Marker lat={defaultCenter.lat} lng={defaultCenter.lng} />
    </GoogleMapReactWithChildren>
  );
};

const Marker: React.FC<TMapCoords> = () => {
  const { t } = useTranslation('map');
  return (
    <picture>
      <Icon
        className="text-brand absolute -left-8 -top-14"
        name="location"
        size="2xl"
        title={t('mapMarker')}
        variant="solid"
      />
    </picture>
  );
};

export const ToolTip: React.FC<
  Pick<TMapProps, 'address' | 'brandCode' | 'ctyhocn' | 'hotelName'> & TMapCoords
> = ({ address, brandCode, ctyhocn, hotelName }) => {
  const { t } = useTranslation('map');
  const addressUrl = getHotelAddressLink({
    addressLine1: address?.addressLine1 || '',
    city: address?.mapCity || '',
    stateCode: address?.state || '',
    countryCode: address?.country || '',
    hotelName: hotelName || '',
  });

  return (
    <InfoWindow
      address={address?.addressFmt}
      brandCode={brandCode}
      ctyhocn={ctyhocn}
      directionsLink={{ linkCopy: t('directions'), url: addressUrl }}
      name={hotelName}
      containerClassName="absolute bottom-12 left-0 right-0 -translate-x-1/2 p-2 w-60 md:w-96"
    />
  );
};

export default Map;
