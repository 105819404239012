import Icon from '@dx-ui/osc-icon';
import { TabList, TabListButton, TabPanel, TabPanels, Tabs } from '@dx-ui/osc-tabs';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import type { GetHotelHomePageQuery, GetHotelLayoutQuery } from '@dx-ui/queries-dx-property-ui';
import { getDistance } from '../../helpers/utils/client';
import { useGlobalConfig } from '../../hooks/use-global-config';
import Map from '../map/map';
import { AirportInfo } from './airport-info';
import { useTheme } from '../../hooks/use-theme';

export type TLocationAndTransportationProps = {
  address: NonNullable<NonNullable<GetHotelLayoutQuery['hotelPageTemplate']>['hotel']>['address'];
  airports?: NonNullable<GetHotelHomePageQuery['hotel']>['airports'];
  brandCode: string;
  coordinate?: NonNullable<
    NonNullable<GetHotelHomePageQuery['hotel']>['localization']
  >['coordinate'];
  hotelName: string;
  ctyhocn: string;
  serviceAmenities?: NonNullable<GetHotelHomePageQuery['hotel']>['serviceAmenities'];
  hideBrandLogo?: boolean;
};

const NearbyLocations = ({
  serviceAmenities,
}: {
  serviceAmenities?: NonNullable<GetHotelHomePageQuery['hotel']>['serviceAmenities'];
}) => {
  const language = useRouter().locale || 'en';
  const { isTailored } = useGlobalConfig();
  const { t } = useTranslation('hotel-location');
  const { theme } = useTheme();

  return (
    <ul>
      {serviceAmenities?.map(({ name, distance, distanceUnit }) => (
        <li
          className={cx('border-border mb-2 flex justify-between border-b p-2', {
            'border-border-alt': !isTailored,
          })}
          key={name}
          data-testid="serviceAmenityElement"
        >
          <div
            className={cx('flex items-center gap-2 pr-5 text-base font-bold', {
              'font-medium': isTailored,
            })}
          >
            <Icon name="location" className={cx({ 'accent-icon': theme.accent?.icon })} />
            <span>{name}</span>
          </div>
          {distance && distanceUnit ? (
            <div className="whitespace-nowrap text-left">
              {getDistance({ t, distance, distanceUnit, language })}
            </div>
          ) : null}
        </li>
      ))}
    </ul>
  );
};

const LocationAndTransportation = ({
  address,
  airports,
  brandCode,
  coordinate,
  ctyhocn,
  hotelName,
  serviceAmenities,
  hideBrandLogo = false,
}: TLocationAndTransportationProps) => {
  const { t } = useTranslation('hotel-location');
  const { theme } = useTheme();

  const sharedTabListButtonProps: Omit<React.ComponentProps<typeof TabListButton>, 'id'> = {
    activeClassName: cx('border-b-border', {
      '!accent-border': theme.accent?.border,
    }),
  };

  return (
    <div className="py-8 lg:py-10" data-testid="locationAndTransportationContainer">
      <h2
        className={cx('heading-2xl lg:heading-3xl mb-8 text-center', {
          'accent-heading': theme.accent?.heading,
        })}
      >
        {t('locationAndTransportation')}
      </h2>
      <div className="container flex flex-row flex-wrap justify-between gap-10 md:flex-nowrap">
        <Tabs className="order-2 w-full flex-col-reverse md:order-1 md:w-1/2">
          <TabList>
            <TabListButton {...sharedTabListButtonProps} id="nearby-locations">
              <h3
                className={cx('heading-base md:heading-2xl', {
                  'accent-heading': theme.accent?.heading,
                })}
              >
                {t('whatsNearby')}
              </h3>
            </TabListButton>
            <TabListButton {...sharedTabListButtonProps} id="airports">
              <h3
                className={cx('heading-base md:heading-2xl', {
                  'accent-heading': theme.accent?.heading,
                })}
              >
                {t('airportInfo')}
              </h3>
            </TabListButton>
          </TabList>
          <TabPanels>
            <TabPanel className="pt-8" id="nearby-locations">
              <NearbyLocations serviceAmenities={serviceAmenities} />
            </TabPanel>
            <TabPanel className="pt-8" id="airports">
              <AirportInfo airports={airports} />
            </TabPanel>
          </TabPanels>
        </Tabs>
        <div className="order-1 aspect-[3/2] w-full sm:h-[421px] md:order-2 md:w-1/2">
          <Map
            coordinate={coordinate}
            hotelName={hotelName}
            brandCode={brandCode}
            address={address}
            ctyhocn={ctyhocn}
            hideBrandLogo={hideBrandLogo}
          />
        </div>
      </div>
    </div>
  );
};

export default LocationAndTransportation;
